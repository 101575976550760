// Brunch automatically concatenates all files in your
// watched paths. Those paths can be configured at
// config.paths.watched in "brunch-config.js".
//
// However, those files will only be executed if
// explicitly imported. The only exception are files
// in vendor, which are never wrapped in imports and
// therefore are always executed.

// Import dependencies
//
// If you no longer want to use a dependency, remember
// to also remove its path from "config.paths.watched".
// import "phoenix_html"

// Import local files
//
// Local files can be imported directly using relative
// paths "./socket" or full ones "web/static/js/socket".

import Dropzone from 'dropzone'
import 'phoenix_html'
import './socket'
import './ace/ace'
import './ace/ext-language_tools'
import './ace/mode-sql'
import './ace/theme-sqlserver'
import './ace/snippets/sql'
import { Sortable } from '@shopify/draggable'

Dropzone.autoDiscover = false

$(window).on("load", () => {
  const a = new Sortable(document.querySelectorAll('.list-container'), {
    draggable: 'li.draggable',
  })

  ace.require("ace/ext/language_tools")

  function initializeEditor(editorId, textareaName) {
    var editorOptions = {
      enableBasicAutocompletion: true,
      enableSnippets: true,
      enableLiveAutocompletion: false,
      autoScrollEditorIntoView: true,
      maxLines: 40,
      minLines: 10
    }
    var editor = ace.edit(editorId)
    editor.session.setMode("ace/mode/sql")
    editor.setTheme("ace/theme/sqlserver")
    editor.setOptions(editorOptions)
    var textarea = $('#feature_type_query')
    editor.getSession().setValue(textarea.val())
    editor.getSession().setUseWrapMode(true)
    editor.setHighlightActiveLine(true)
    editor.getSession().on('change', function(){
      textarea.val(editor.getSession().getValue())
    })
  }

  if ($('#query_editor').length) {
    initializeEditor("query_editor", "query")
  }

  if ($("#menu-image-dropzone").length) {
    let token = $("meta[name=token]").attr("content")

    const menuImageDropzone = new Dropzone("#menu-image-dropzone", {
      maxFiles: 1,
      maxFileSize: 50,
      acceptedFiles: 'image/svg+xml',
      headers: { "Authorization": `Bearer ${token}` },
      addRemoveLinks: true,
      thumbnailWidth: 64
    })
    menuImageDropzone.on("complete", function(file) {
      const body = JSON.parse(file.xhr.response)
      $('#menu-image').attr('value', body.content)
    })

    const markerImageDropzone = new Dropzone("#marker-image-dropzone", {
      maxFiles: 1,
      maxFileSize: 50,
      acceptedFiles: 'image/png',
      headers: { "Authorization": `Bearer ${token}` },
      addRemoveLinks: true,
      thumbnailWidth: 64
    })
    markerImageDropzone.on("complete", function(file) {
      const body = JSON.parse(file.xhr.response)
      $('#marker-image').attr('value', body.file_url)
    })

    const markerSelectedImageDropzone = new Dropzone("#marker-selected-image-dropzone", {
      maxFiles: 1,
      maxFileSize: 50,
      acceptedFiles: 'image/png',
      headers: { "Authorization": `Bearer ${token}` },
      addRemoveLinks: true,
      thumbnailWidth: 64
    })
    markerSelectedImageDropzone.on("complete", function(file) {
        const body = JSON.parse(file.xhr.response)
        $('#marker-selected-image').attr('value', body.file_url)
    })
  }
})
