import { Socket } from "phoenix";
import moment from "moment";

let token = $("meta[name=token]").attr("content");
let socket;

if (token) {
  socket = new Socket("/socket", { params: { token } });
  socket.connect();

  let channel = socket.channel("import");
  channel
    .join()
    .receive("ok", resp => { })
    .receive("error", resp => {
      console.error("Unauthorized", resp);
    });

  $("#start-feature-import").on("click", () => {
    const feature_type_id = $("#feature_type_id").text();
    channel.push("start_feature_import", {
      feature_type_id: feature_type_id,
      reset_fields: $("#reset-fields-check").prop("checked")
    });
  });

  $("#start-photo-import").on("click", () => {
    channel.push("start_photo_import", {});
  });

  $("#start-document-import").on("click", () => {
    channel.push("start_document_import", {});
  });

  $("#start-user-import").on("click", () => {
    channel.push("start_user_import", {});
  });

  $("#start-work-order-import").on("click", () => {
    channel.push("start_work_order_import", {});
  });

  $("#start-link-features").on("click", () => {
    const feature_type_id = $("#feature_type_id").text();
    channel.push("start_link_features", { feature_type_id: feature_type_id });
  });

  channel.on("import_started", payload => {
    $("#report").html("");
    $("#start-feature-import")
      .html('<i class="fa fa-circle-o-notch fa-spin"></i> Importing...')
      .addClass("disabled");
    $("#start-photo-import")
      .html('<i class="fa fa-circle-o-notch fa-spin"></i> Importing...')
      .addClass("disabled");
    $("#start-document-import")
      .html('<i class="fa fa-circle-o-notch fa-spin"></i> Importing...')
      .addClass("disabled");
    $("#start-user-import")
      .html('<i class="fa fa-circle-o-notch fa-spin"></i> Importing...')
      .addClass("disabled");
    $("#start-work-order-import")
      .html('<i class="fa fa-circle-o-notch fa-spin"></i> Importing...')
      .addClass("disabled");
    $("#start-link-features")
      .html('<i class="fa fa-circle-o-notch fa-spin"></i> Linking...')
      .addClass("disabled");
    $("#report").show();
    $("#report").append(
      "<p><strong>[" +
      moment().format("h:mm:ss a") +
      "]</strong> " +
      payload.message +
      "</p>"
    );
  });

  channel.on("import_success", payload => {
    $("#start-feature-import")
      .html("Start Feature Import!")
      .removeClass("disabled");
    $("#start-photo-import")
      .html("Photo Import")
      .removeClass("disabled");
    $("#start-document-import")
      .html("Document Import")
      .removeClass("disabled");
    $("#start-user-import")
      .html("User Import")
      .removeClass("disabled");
    $("#start-work-order-import")
      .html("Work Order Import")
      .removeClass("disabled");
    $("#start-link-features")
      .html("Start Linking Features!")
      .removeClass("disabled");
    $("#report").append(
      '<p style="color: #249D3D;"><strong>[' +
      moment().format("h:mm:ss a") +
      "]</strong> " +
      payload.message +
      "</p>"
    );
  });

  channel.on("import_error", payload => {
    let res = payload;
    if (typeof payload === "object") {
      res = payload.message;
    }
    $("#start-feature-import")
      .html("Start Feature Import!")
      .removeClass("disabled");
    $("#start-photo-import")
      .html("Photo Import")
      .removeClass("disabled");
    $("#start-document-import")
      .html("Document Import")
      .removeClass("disabled");
    $("#start-user-import")
      .html("User Import")
      .removeClass("disabled");
    $("#start-work-order-import")
      .html("Work Order Import")
      .removeClass("disabled");
    $("#start-link-features")
      .html("Start Linking Features!")
      .removeClass("disabled");
    $("#report").append(
      '<p style="color: crimson;"><strong>[' +
      moment().format("h:mm:ss a") +
      "]</strong> " +
      res +
      "</p>"
    );
  });

  // Shapefile import
  $("#start-shapefile-import").on("click", () => {
    const id = $("#shapefile-id").attr("data");
    const srid = $("#srid").val();
    const fields = {};
    $("#fields")
      .children()
      .each((idx, el) => {
        fields[el.id] = {
          import: $(el)
            .find("#do-import")
            .is(":checked"),
          name: $(el)
            .find("#field-name")
            .val(),
          type: $(el)
            .find("#field-type")
            .val()
        };
      });
    const feature_type_id = $("#feature_type_id").val();
    if (id)
      channel.push("start_shapefile_import", {
        id: id,
        fields: fields,
        srid: srid,
        feature_type_id: feature_type_id
      });
  });

  channel.on("shapefile_import_started", payload => {
    $("#report").html("");
    $("#start-shapefile-import")
      .html('<i class="fa fa-circle-o-notch fa-spin"></i> Importing...')
      .addClass("disabled");
    $("#report").show();
    $("#report").append(
      "<p><strong>[" +
      moment().format("h:mm:ss a") +
      "]</strong> " +
      payload.message +
      "</p>"
    );
  });

  channel.on("shapefile_import_success", payload => {
    $("#start-shapefile-import")
      .html("Start Shapefile Import!")
      .removeClass("disabled");
    $("#report").append(
      '<p style="color: #249D3D;"><strong>[' +
      moment().format("h:mm:ss a") +
      "]</strong> " +
      payload.message +
      "</p>"
    );
  });

  channel.on("shapefile_import_error", payload => {
    var res = payload;
    if (typeof payload === "object") {
      res = payload.message;
    }
    $("#start-shapefile-import")
      .html("Start Shapefile Import!")
      .removeClass("disabled");
    $("#report").append(
      '<p style="color: crimson;"><strong>[' +
      moment().format("h:mm:ss a") +
      "]</strong> " +
      res +
      "</p>"
    );
  });

  // Rotation
  $('#update-and-compute').on('click', function () {
    const attrs = {
      name: $("#inspection_program_name").val(),
      priority: $("#inspection_program_priority").val(),
      feature_type_id: $("#inspection_program_feature_type_id").val(),
      parent_feature_id: $("#inspection_program_parent_feature_id").val(),
      work_order_type_id: $("#inspection_program_work_order_type_id").val(),
      filter_id: $("#inspection_program_filter_id").val(),
      display: $("#inspection_program_display").val()
    }
    channel_rotation.push("update_and_compute", { inspection_program_id: $("#inspection_program_id").val(), attrs });
    $(this).addClass('disabled');
    $('#report').html('');
  });

  let channel_rotation = socket.channel("rotation")
  channel_rotation.join()
    .receive("ok", resp => { })
    .receive("error", resp => { console.error("Unauthorized", resp) })

  channel_rotation.on("started", payload => {
    var res = payload
    if (typeof (payload) === 'object') {
      res = payload.message
    }
    $('#report').show().append('<p><strong>[' + moment().format('h:mm:ss a') + ']</strong> ' + payload.message + '</p>')
  })

  channel_rotation.on("completed", payload => {
    var res = payload
    if (typeof (payload) === 'object') {
      res = payload.message
    }
    $('#update-and-compute').removeClass('disabled')
    $('#report').show().append('<p><strong>[' + moment().format('h:mm:ss a') + ']</strong> ' + payload.message + '</p>')
  })

  channel_rotation.on("rotation_error", payload => {
    var res = payload
    if (typeof (payload) === 'object') {
      res = payload.message
    }
    $('#update-and-compute').removeClass('disabled')
    $('#report').show().append('<p><strong>[' + moment().format('h:mm:ss a') + ']</strong> ' + payload.message + '</p>')
  })
}

window.socket = socket;
export default socket;
